import { mapGetters } from 'vuex'
import AppConfig from 'config'

export default {
  name: 'AppAnchor',

  data() {
    return {
      AppConfig
    }
  },

  computed: {
    ...mapGetters({
      config: 'anchor/getConfig'
    })
  },

  mounted() {
    const self = this

    // MAIN ANCHOR TO TOP
    function navSlide() {
      const scrollTop = $(window).scrollTop()

      self.$el.className = (scrollTop >= 300) ? 'active' : null

      if (self.$route.name === self.$routeResolver('routes.search.list')) {
        if (scrollTop + $(window).height() > $(document).height() - 100) {
          self.$el.className = null

          self.$emit('inactivateAnchor')
        }
      }
    }

    $(window).scroll(navSlide)
  },

  methods: {
    scrollTop() {
      $('html, body').animate({
        scrollTop: $($('body')).offset().top
      }, 600)
    }
  }
}
